.info-section {

    --headerWidth: 664px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    min-height: 100vh;
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: var(--padding);

    &::after {
        content: '';
        width: 100vw;
        position: absolute;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
        height: 70%;
        bottom: 0;
    }

    .info-section__body {
        position: relative;
        max-width: 1040px;
        width: 100%;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;


        .header-container {
            width: var(--headerWidth);



            .images {
                position: absolute;
                right: 0;
                top: 20px;
                transform: translateX(0px);
                transition: transform 0.5s ease;
                z-index: -1;
                width: 320px;
                height: 240px;

                .images-container {
                    position: relative;

                    .card1 {
                        transform: rotate(8deg);
                        width: 100%;
                        height: 100%;
                    }

                    .card2 {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        transform: rotate(-8deg);
                        top: 90%;
                        right: -23%;
                    }
                }
            }

        }

        .text-container {
            display: flex;
            flex-direction: column;



            h2,
            p {
                max-width: var(--headerWidth);
            }
        }


        .telegram-btn {
            position: relative;


        }

    }



}



.background {
    position: absolute;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

body {
    position: relative;
    background-color: var(--bg-100);
}


@media screen and (max-width: 1110px) {
    .info-section {
        .info-section__body {
            .header-container {}
        }
    }


}

@media screen and (max-width: 1023px) {
    .info-section {
        .info-section__body {
            .header-container {
                width: 340px;
            }
        }

        .info-link {
            align-self: center;
        }


    }
}

@media screen and (max-width: 900px) {
    .info-section {
        .info-section__body {
            .header-container {
                .images {
                    transform: translateX(-70px) translateY(-95px);
                    width: 240px;
                    height: 180px;

                    .images-container {
                        .card2 {
                            transform: rotate(-8deg) translateX(43px) translateY(-20px);
                        }
                    }

                }

            }
        }
    }
}






@media screen and (max-width: 660px) {

    .info-section {
        --headerWidth: none;

        .info-section__body {
            align-items: center;

            .header-container {
                gap: 24px;
                flex-direction: column-reverse;
                align-items: center;
                display: flex;
                width: 100%;

                h1 {
                    align-self: start;
                    max-width: 312px;

                }


                .images {
                    position: relative;
                    width: 100%;
                    height: auto;
                    max-width: 280px;
                    top: 0;
                    transform: translateY(-60px) translateX(-20px);

                    .images-container {
                        .card2 {
                            transform: rotate(-8deg) translateX(25px) translateY(-132px);
                        }
                    }


                }
            }

            .text-container {
                margin-top: 0px;


            }



            .images {
                width: 200px;
                height: 200px;
            }

            .info-link {
                align-self: center;
            }

            .telegram-btn {}

        }



    }
}