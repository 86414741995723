:root {
    --color-white: #fff;
    --color-black: #000000;

    --bg-100: #030305;
    --accent: #EF6500;

    font-family: 'Sora', sans-serif;
    font-weight: 400;
    text-align: left;
    color: var(--color-white);

    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;


    --text-stroke: 1px var(--color-black);

    --h1-size: 32px;
    --h2-size: 20px;

    --p-size: 16px;


    --h1-lh: 48px;
    --h2-lh: 28px;

    --p-lh: 24px;


    --h1-mb: 40px;
    --h2-mb: 24px;
    --p-mb: 96px;

    --btn-size: 24px;
    --btn-lh: 24px;
    --btn-padding: 21px 48px 19px 48px;


    /* ---------------------------------- PEPE ---------------------------------- */


    --arrow-width: 56px;
    --arrow-height: 48px;

    --padding: 70px 24px 70px 24px;

}


@media screen and (min-width:660px) {
    :root {
        --h1-size: 48px;
        --h2-size: 32px;

        --p-size: 24px;


        --h1-lh: 72px;
        --h2-lh: 48px;

        --p-lh: 40px;

        --h1-mb: 80px;
        --h2-mb: 48px;
        --p-mb: 120px;


        --arrow-width: 83px;
        --arrow-height: 72px;

        --padding: 186px 40px;

        --button-border: 2px solid var(--color-black);

    }
}


@media screen and (min-width:1024px) {
    :root {
        --h1-size: 48px;


        --h1-lh: 72px;

        --h1-mb: 72px;
        --h2-mb: 48px;
        --p-mb: 96px;

        --text-stroke: 2px var(--color-black);

        --padding: 80px;
    }
}