/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@400;700&display=swap');


/* General styles */
body {
	position: relative;
	margin: 0 auto;
	-webkit-overflow-scrolling: touch;
	scroll-behavior: smooth;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: var(--color-white);

}

.accent {
	color: var(--accent);
}

.spacer {
	width: 100%;
	height: calc(var(--headerHeight));
}

/* Headings */
h1 {
	font-size: var(--h1-size);
	font-weight: 700;
	line-height: var(--h1-lh);
	margin-bottom: var(--h1-mb);
	text-transform: uppercase;
}

h2 {
	font-size: var(--h2-size);
	font-weight: 700;
	line-height: var(--h2-lh);
	margin-bottom: var(--h2-mb);
	font-family: 'Inder', sans-serif;
}


/* Paragraphs */
p {
	max-width: var(--texts-width);
	font-size: var(--p-size);
	font-weight: 400;
	line-height: var(--p-lh);
	margin-bottom: var(--p-mb);
	font-family: 'Inder', sans-serif;
}



/* Images */
img {
	pointer-events: none;
	user-select: none;
}

/* Main Container */
main {
	position: relative;
	max-width: 1440px;
	margin: 0 auto;
}

/* Buttons */
button.button,
a.button {
	padding: var(--btn-padding);
	border-radius: 48px;
	background: var(--accent);
	flex-grow: 0;
	font-size: var(--btn-size);
	line-height: var(--btn-lh);
	font-weight: 700;
	text-align: center;
	position: relative;
	cursor: pointer;
	transition: all 0.2s ease;
	color: var(--color-white);

	&:hover {
		background: #EF9E00;
		box-shadow: 0px 0px 10px 1px rgba(255, 135, 47, 1);
	}

}






/* Media Queries */
@media screen and (max-width: 1023px) {

	p.no-max-tbl {
		max-width: none;
	}
}


.reveal {
	opacity: 0;
	-webkit-transform: translateY(40px);
	transform: translateY(40px);
	transition: opacity .4s ease, -webkit-transform .4s ease;
	transition: opacity .4s ease, transform .4s ease;
	transition: opacity .4s ease, transform .4s ease, -webkit-transform .4s ease
}

.reveal.order-1 {
	transition-delay: .1s
}

.reveal.order-2 {
	transition-delay: .2s
}

.reveal.order-3 {
	transition-delay: .3s
}

.reveal.order-4 {
	transition-delay: .4s
}

.reveal.order-5 {
	transition-delay: .5s
}

.reveal.order-6 {
	transition-delay: .6s
}

.reveal.order-7 {
	transition-delay: .7s
}

.animated {
	opacity: 1;
	-webkit-transform: none;
	transform: none;
	transition: opacity .4s ease, -webkit-transform .4s ease;
	transition: opacity .4s ease, transform .4s ease;
	transition: opacity .4s ease, transform .4s ease, -webkit-transform .4s ease
}


@media screen and (max-width: 400) {

	.reveal {
		opacity: 1;
		transform: none;
	}
}